import React from 'react'
import { Typography } from './Typography'
import { css } from '../../../styled-system/css'
import { Trash2 } from 'lucide-react'
import { BackgroundManagementProps } from '../../types/backgrounds.manage'
import ResponsiveImage from './ResponsiveImage'

export const BackgroundsManager = ({
  themes,
  selectedThemes,
  setSelectedThemes,
}: BackgroundManagementProps) => {
  let selectedThemesFree = selectedThemes.filter((value) => {
    const theme = themes.find((theme) => theme.id === value)
    return theme?.price.value === 0
  })

  return (
    <div
      className={css({
        position: 'fixed',
        bottom: "0",
        bg: '#F2D98D',
        padding: '.25rem 0',
        w: '100vw',
        zIndex: '999999',
        lgDown: {
          display: "none"
        }
      })}
    >
      <div className={css({
        bg: '#000',
        w: '100%',
        display: 'flex',
        justifyContent: 'left'
      })}>
        <div
          className={css({
            display: 'flex',
            flexDir: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            p: '0 1rem'
          })}
        >
          <p className={css({
            fontFamily: 'Assistant',
            fontSize: '1.25rem',
            fontWeight: '500'
          })}>
            Vos fonds sélectionnés: {" "}
            <span>
              {selectedThemes.length}
              {selectedThemesFree.length === 0 ? null : (
                <span className={css({
                  fontWeight: '300',
                })}>{" "}(Dont {selectedThemesFree.length} gratuits)</span>
              )}
            </span>
          </p>
        </div>

        <div
          className={css({
            display: 'flex',
            gap: '.5rem'
          })}
        >
          {selectedThemes.map((themeId) => {
            const theme = themes.find((theme) => theme.id === themeId)

            if (!theme) {
              return null
            }

            return (
              <div
                className={css({
                  // flex: '1',
                  minW: '150px',
                  height: '100px',
                  cursor: 'pointer',
                  pos: 'relative',
                  transition: '.3s',

                  _hover: {
                    '& > div': {
                      display: 'flex',
                    },
                  },
                })}
                key={theme.id}
              >
                <ResponsiveImage
                  className={css({
                    h: '100%',
                    w: '100%',
                    objectFit: 'cover',
                    transition: '.3s',
                  })}
                  relativePath={`themes/${theme.image}`}
                />

                <div
                  className={css({
                    pos: 'absolute',
                    display: 'none',
                    flexDir: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '3px',
                    bg: 'rgba(255, 255, 255, .4)',
                    w: '100%',
                    h: '100%',
                    top: '0',
                    left: '0',
                  })}
                  onClick={() =>
                    setSelectedThemes((prevV) =>
                      prevV.filter((_theme) => _theme !== theme.id)
                    )
                  }
                >
                  <Trash2 />
                  <Typography as="span">Supprimer</Typography>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div >
  )
}
