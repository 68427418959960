import { packages } from '@dup/products'
import React from 'react'
import { css, cx } from '../../../styled-system/css'
import ResponsiveImage from './ResponsiveImage'

type PackageImagesProps = {
  id: string
  amount?: number
  className?: string
  special?: boolean
  startAt?: number
}

export const PackageImages = ({
  id,
  amount = 3,
  className,
  special,
  startAt = 0,
}: PackageImagesProps) => {
  const _package = packages.find((pkg) => pkg.id === id)

  if (!_package) {
    return null
  }

  return (
    <div
      className={cx(
        css({
          display: 'flex',
          p: '.625rem 0',
          bg: '#F2D98D',
          flexWrap: 'wrap',
          lgDown: {
            p: '.25rem 0',
          }
        }),
        className
      )}
    >
      {_package.images
        .slice(
          startAt,
          amount > _package.images.length ? _package.images.length : amount
        )
        .map((img, idx) => {
          const isEven = idx % 2 === 0

          const evenStyle = special && isEven ? { flex: '1.5' } : { flex: '1' }
          const finalStyle = { ...evenStyle }

          return (
            <div
              style={finalStyle}
              key={idx}
              className={css({
                background: '#000',
              })}
            >
              <ResponsiveImage
                className={css({
                  w: '100%',
                  h: '100%',
                  objectFit: 'cover',
                })}
                relativePath={`packages/${id}/${img.name}`}
              />
            </div>
          )
        })}
    </div>
  )
}
